import type { FC } from 'react'

import { useEffect, useState, useCallback } from 'react'
import { v4 as uuid } from 'uuid'
import { useNavigate } from 'react-router'

import { Typography } from '@/shared/components/typography'
import { Button } from '@/shared/components/button'
import { Input } from '@/shared/components/input'
import { usePaymentTokenContext } from '@/shared/contexts/token-context/token-context'

import styles from './token-page.module.scss'
import { MANAGEMENT_CARD_PAGE } from '@/shared/constants/routes'
import { SearchParams } from '@/shared/constants/search-params'
import { TOKEN_PAGE_DATA_ID } from '@/shared/constants/test/data-testid'

export const TokenPage: FC = () => {
  const navigate = useNavigate()

  const { token, error, setToken, loading } = usePaymentTokenContext()
  const [newToken, setNewToken] = useState<string | null>(token)

  const generateNewPayload = useCallback(() => {
    const iat = Math.floor(Date.now() / 1000)
    const exp = iat + 30 * 60
    const nbf = iat - 300

    return {
      exp,
      iat,
      nbf,
      order_id: uuid(),
      user_id: uuid(),
      amount_value: 150013,
      amount_currency: 'RUB',
      country_id: 1,
      merchant_id: 1,
      language_code: 'en',
    }
  }, [])

  const [forGenerate, setForGenerate] = useState(
    JSON.stringify(generateNewPayload(), null, 2),
  )

  const deleteToken = () => {
    setToken(null)
    setNewToken(null)
  }

  useEffect(() => {
    if (error) {
      console.debug(error)
      alert(
        `Ошибка при обновлении токена!\n${JSON.stringify(
          error?.response?.data?.detail,
        )}`,
      )
    }
  }, [error])

  const toBuy = useCallback(() => {
    if (newToken) {
      setToken(newToken)
    }
  }, [newToken])

  const toManagement = useCallback(() => {
    if (newToken) {
      navigate({
        pathname: MANAGEMENT_CARD_PAGE,
        search: new URLSearchParams({
          [SearchParams.Token]: newToken,
        }).toString(),
      })
    }
  }, [newToken])

  return (
    <div className={styles['payment']}>
      <div className={styles['payment-body']}>
        <Typography
          fontSize="15"
          lineHeight="20"
          letterSpacing="mini"
          color="white-90"
        >
          Token:
        </Typography>
        <div className={styles['generate-block']}>
          <div className={styles['generate-block-row']}>
            <Input
              data-testid={TOKEN_PAGE_DATA_ID.TOKEN_INPUT}
              value={newToken ?? ''}
              onChange={({ target }) =>
                setNewToken((target as HTMLInputElement).value)
              }
            />
            <Button
              onClick={deleteToken}
              type="submit"
              size="small"
              variant="outline"
            >
              Удалить jwt
            </Button>
          </div>
        </div>
        <div className={styles['generate-block']}>
          <div className={styles['generate-block-row']}>
            <textarea
              value={forGenerate}
              onChange={({ target: { value } }) => setForGenerate(value)}
            />
          </div>
        </div>
        <div className={styles['generate-block-row']}>
          <Button
            onClick={() =>
              setForGenerate(JSON.stringify(generateNewPayload(), null, 2))
            }
            type="submit"
            size="small"
            variant="outline"
          >
            Перегенерировать payload
          </Button>
          <a target="_blank" href="https://jwt.io/">
            <Button type="submit" size="small" variant="outline">
              Открыть сайт
            </Button>
          </a>
          <Typography color="white-100">
            Секрет(base64): {btoa('secret')}
          </Typography>
        </div>
        <div className={styles['generate-block-row']}>
          <Button
            data-testid={TOKEN_PAGE_DATA_ID.PAYMENT_BTN}
            onClick={toBuy}
            type="submit"
            size="small"
            variant="outline"
            disabled={!newToken || !!error || loading}
          >
            К оплате
          </Button>
          <Button
            data-testid={TOKEN_PAGE_DATA_ID.MANAGEMENT_BTN}
            onClick={toManagement}
            type="submit"
            size="small"
            variant="outline"
            disabled={!newToken}
          >
            Управление картами
          </Button>
        </div>
      </div>
    </div>
  )
}
