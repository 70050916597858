import { FC, useEffect } from 'react'

import { Typography } from '@/shared/components/typography'
import { useLanguage } from '@/shared/contexts/language-context'
import { ReactComponent as PaymentFailedIcon } from '@/shared/icons/payment-failed-icon.svg'
import { useMediaContext } from '@/shared/contexts/media-context/media-context'
import { postEventToParent } from '@/shared/utils/post-event-po-parent'
import { ParentEvents } from '@/shared/constants/parent-events'
import { Button } from '@/shared/components/button'

import styles from './payment-result.module.scss'
import classNames from 'classnames'

export const PaymentFailed: FC = () => {
  const { getField } = useLanguage()
  const { isScreenSm } = useMediaContext()

  const onRetry = () => {
    postEventToParent({ event: ParentEvents.ShowIframeSmallScreen })
    postEventToParent({ event: ParentEvents.FailedRetryButtonClick })
  }

  useEffect(() => {
    postEventToParent({ event: ParentEvents.FailedPageOpen })
  }, [])

  return (
    <div className={styles['payment']}>
      <div
        className={classNames(
          styles['payment-body'],
          isScreenSm && styles['payment-body_mobile'],
        )}
      >
        <div className={styles['top-slot']}>
          <div className={styles['top-slot-icon']}>
            <PaymentFailedIcon />
          </div>
          <Typography
            className={styles['header-text']}
            tag="span"
            fontSize={isScreenSm ? '24' : '35'}
            letterSpacing="micro"
            color="white-100"
          >
            {getField('payment_failed_title')}
          </Typography>
        </div>
        <Button
          variant="fill"
          className={classNames(
            styles['button-back'],
            isScreenSm && styles['button-back_mobile'],
          )}
          onClick={onRetry}
        >
          {getField('payment_failed_retry_button')}
        </Button>
      </div>
    </div>
  )
}
